.auth-container {
  margin-top: 12%;
}
.modal-header,
.modal-body,
.modal-footer {
  background-color: #002948;
  color: White;
}

.verification-modal-text {
  margin: 2%;
}

.verification-code {
  border-radius: none !important;
}

.input-group {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.input-fields {
  display: flex;
  width: -webkit-fill-available;

  .number {
    border-radius: 0%;
  }
  a:hover {
    text-decoration: none;
  }
  .button {
    border-radius: 0%;
    color: white;
    display: block;
  }

  .button:hover {
    border-color: #005595;
    background-color: #005595;
  }
}

.title {
  font-size: 45px;
  font-family: "Courier New", Courier, monospace;
}

@media (max-width: 576px) {
  .auth-container {
    margin-top: 50%;
  }
  .input-group {
    width: 100%;
  }

  .input-fields {
    width: 100%;
  }

  .title {
    font-size: 25px;
  }
}
