.order-container {
  margin-top: 10%;

  .button {
    border-radius: 0%;
    color: white;
    margin: 1%;
  }

  .button:hover {
    border-color: #005595;
    background-color: #005595;
  }
}
