.home-container {
  .logged-user {
    font-size: x-large;
    font-weight: bold;
  }
  .card {
    border: none;
    margin-top: 4%;
    font-size: large;
    .card-title {
      margin: 2%;
      margin-bottom: 4%;
    }
    .card-text {
      text-align: left;
    }

    .order-submit {
      margin-top: 10%;
    }
  }

  .find-options,
  .find-results {
    margin: 1% -1%;
  }
  .search-button,
  .scan-button {
    width: 48%;
    font-size: large;
    align-content: flex-start;
    margin: 1%;
  }
  .search-field {
    margin-top: 10%;
  }
  .spacer {
    margin-top: 5%;
  }
}
